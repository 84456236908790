import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'shop',
    component: () => import(/* webpackChunkName: "shop" */  '../views/Shop.vue')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */  '../views/Unauthorized.vue')
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */  '../views/Forbidden.vue')
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.path === '/') {
    if (!store.state.sessionId) {
      const e = await store.dispatch('login', to.query);
      if (e === 401) {
        router.push('/unauthorized');
        return;
      }
      if (e === 403) {
        router.push('/forbidden');
        return;
      }
    }
  }
  next();
});

export default router
