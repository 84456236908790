<template lang='pug'>
v-card.cart(width='600')
    v-app-bar(flat color='primary')
        v-toolbar-title.text-h6.white--text.pl-0.mr-5 {{ t('cartTotalText') }}: € {{ getTotalPrice() }}
        v-spacer
        v-btn(color='white' icon @click='$emit("close")')
            v-icon mdi-close
    v-list.list(v-if='cartProducts.length > 0')
        template(v-for='product of cartProducts' )
            v-list-item(:key='"1" + product.ItemCode')
                v-skeleton-loader.mr-3(v-if='!getImage(product.ItemCode)' type='avatar' boilerplate)
                v-list-item-avatar(v-if='getImage(product.ItemCode)' size='48')
                    v-img(:src='"data:image/png;base64, " + getImage(product.ItemCode)')
                v-list-item-content
                    v-list-item-title {{ product.ItemOmschrijving }}
                    v-list-item-subtitle #[i {{ product.ProductGroepOmschrijving }}]
                    v-list-item-subtitle #[b {{ t('quantityText') }}:] {{ product.amount }}
                v-list-item-action
                    v-row(dense no-gutters align='center')
                        span.mx-3 #[b €{{ parseFloat(getPrice(product)).toFixed(2) }}]
                        v-btn(icon @click='minus(product.ItemCode)')
                            v-icon(color='primary') mdi-minus
                        v-btn(icon @click='plus(product.ItemCode)')
                            v-icon(color='primary') mdi-plus
                        v-btn(icon @click='remove(product.ItemCode)')
                            v-icon(color='red') mdi-delete
            v-divider(:key='"2" + product.ItemCode')
    v-card-text(v-else) {{ t('emptyCartText') }}
    v-card-actions(v-if='cartProducts.length > 0')
        v-spacer
        v-dialog(v-model='dialog' width='400' content-class='absolute')
            template(v-slot:activator='{ on, attrs }')
                v-btn(text color='primary' v-bind='attrs' v-on='on') {{ t('orderText') }}
            v-card
                v-card-title {{ t('orderText') }}
                v-card-text.pb-0 {{ t('orderSummaryText1') }} {{ amountOfProducts }} {{ t('orderSummaryText2') }} €{{ getTotalPrice() }} Euro.
                    p(v-if='orderText') {{ orderText }}
                    p(v-if='cartProducts.some(p => p.SpecialOrder) && showSpecialOrderText') {{ specialOrderText1 }} #[v-icon mdi-alert]. {{ specialOrderText2 }}
                    p.red-text(v-if='cartProducts.some(p => p.SpecialOrder) && showSpecialOrderText') {{ specialOrderWarningText }}
                    v-textarea(v-if='includeOrderNumber' v-model='orderNumber' :label='t("orderNumberText")' clearable rows='1' auto-grow)
                    v-textarea(v-model='comment' :label='t("commentText")' clearable rows='1' auto-grow)
                    v-checkbox(v-model='terms' :label='t("termsText")')
                v-card-actions
                    v-spacer
                    v-btn(text @click='dialog = false') {{ t('cancelText') }}
                    v-btn(color='primary' text @click='confirm' :disabled='!terms') {{ t('confirmText') }}
</template>

<script>
export default {
  name: 'Cart',
  data() {
    return {
        dialog: false,
        orderNumber: null,
        comment: null,
        terms: false
    }
  },
  computed: {
    cartProducts () {
        let cartProducts = [];
        for (const product in this.$store.state.cart) {
            const amount = this.$store.state.cart[product];
            if (amount > 0) {
                const productInformation = this.$store.state.products.find((p) => product === p.ItemCode);
                cartProducts.push({ ...productInformation, amount });
            }
        }
        return cartProducts;
    },
    amountOfProducts() {
        let amount = 0;
        for (const product in this.$store.state.cart) {
            amount += this.$store.state.cart[product];
        }
        return amount;
    },
    showSpecialOrderText() { return this.$store.state.showSpecialOrderText },
    specialOrderText1() { return this.$store.state.specialOrderText1 },
    specialOrderText2() { return this.$store.state.specialOrderText2 },
    specialOrderWarningText() { return this.$store.state.specialOrderWarningText },
    includeOrderNumber() { return this.$store.state.includeOrderNumber },
    orderText() { return this.$store.state.orderText }
  },
    methods: {
        minus(itemCode) {
            this.$set(this.$store.state.cart, itemCode, this.$store.state.cart[itemCode] - 1);
        },
        plus(itemCode) {
            this.$set(this.$store.state.cart, itemCode, this.$store.state.cart[itemCode] + 1);
        },
        remove(itemCode) {
            this.$delete(this.$store.state.cart, itemCode);
        },
        getPrice(product) {
            const pricePerItem = this.$store.state.price[product.ItemCode];
            //return parseFloat(pricePerIte?.Prijs_per_eenheid) * parseFloat(product.amount);
            return parseFloat(pricePerItem?.Pp_eenheid || pricePerItem?.Prijs_per_eenheid) * parseFloat(product.amount);
        },
        getTotalPrice() {
            let total = 0;
            for (const product of this.cartProducts) {
                total += this.getPrice(product);
            }
            return parseFloat(total).toFixed(2);
        },
        getImage(itemCode) {
            return this.$store.state.smallImage[itemCode]?.FeaturedImageSmall;
        },
        confirm() {
            this.$emit("confirm", {orderNumber: this.orderNumber, comment: this.comment, products: this.cartProducts });
            this.dialog = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.list {
    overflow-y: scroll;
    max-height: 500px;
}
::v-deep .v-text-field__details {
    display: none;
}
.red-text {
    color: red;
}
::v-deep .absolute {
    margin-top: 88px; // aligned with cart top
    align-self: flex-start;
}
</style>