import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import Vuetify from '../plugins/vuetify'

Vue.use(Vuex);

let API_URL = 'https://outsiteshop.simplexconnect.nl';
if (process.env.NODE_ENV === 'development'){
  API_URL = 'http://localhost:3000'
}
if (window.location.href.includes('simplexoutsiteshop-dev')) {
  API_URL = 'https://simplexoutsiteshop-dev.azurewebsites.net'
}
if (window.location.href.includes('localhost')) {
  API_URL = 'http://localhost:3000'
}

axios.interceptors.request.use(async (config) => {
  if (!config.url.endsWith('/login')) {
    await store.state.loginPromise;
  }
  if (store.state.sessionId) {
    config.headers['session-id'] = store.state.sessionId;
  }
  return config;
});

const store = new Vuex.Store({
  state: {
    cart: {},
    products: [],
    filteredProducts: [],
    recentlyPurchased: [],
    recentlyPurchasedFilter: false,
    search: null,
    categoryFilters: [],
    stock: {},
    price: {},
    smallImage: {},
    images: {},
    sessionId: null,
    loginPromise: null,
    showStock: false,
    showAppBar: false,
    fullscreen: false,
    environment: null,
    language: "english",
    showSpecialOrderText: false,
    specialOrderText1: '',
    specialOrderText2: '',
    includeOrderNumber: false,
    includeContactId: false,
    specialOrderWarningText: '',
    websiteName: 'OutSiteShop',
    colorCode: '#00a38c',
    orderText: '',
    orderError: '',
    defaultAmount: 1
  },
  getters: {
  },
  mutations: {
    setFilteredProducts(state, filteredProducts) {
      state.filteredProducts = filteredProducts;
    },
    setColorCode(state, payload) {
      Vuetify.framework.theme.themes.light.primary = payload
    }
  },
  actions: {
    async login(ctx, payload) {
      let resolveLoginPromise;
      ctx.state.loginPromise = new Promise((res) => resolveLoginPromise = res);
      try {
        const res = await axios.post(`${API_URL}/login`, payload);
        ctx.state.sessionId = res.data.sessionId;
        ctx.state.showAppBar = res.data.showAppBar;
        ctx.state.environment = res.data.environment;
        ctx.state.language = res.data.language;
        ctx.state.showSpecialOrderText = res.data.showSpecialOrderText
        ctx.state.specialOrderText1 = res.data.specialOrderText1
        ctx.state.includeOrderNumber = res.data.includeOrderNumber
        ctx.state.includeContactId = res.data.includeContactId
        ctx.state.specialOrderText2 = res.data.specialOrderText2
        ctx.state.specialOrderWarningText = res.data.specialOrderWarningText
        ctx.state.websiteName = res.data.websiteName
        ctx.state.orderText = res.data.orderText
        ctx.state.defaultAmount = res.data.defaultAmount
        ctx.commit("setColorCode", res.data.colorCode)
      } catch (e) {
        return e.response.status;
      }
      resolveLoginPromise();
    },
    async getProducts(ctx) {
      const res = await axios.get(`${API_URL}/getProducts`);
      ctx.state.products = res.data;
    },
    async getStock(ctx, itemCode) {
      const res = await axios.get(`${API_URL}/getStock?itemCode=${encodeURIComponent(itemCode)}`);
      Vue.set(ctx.state.stock, itemCode, res.data);
    },
    async getRecentlyPurchased(ctx) {
      const res = await axios.get(`${API_URL}/getRecentlyPurchased`);
      ctx.state.recentlyPurchased = res.data;
    },
    async getPrice(ctx, itemCode) {
      const res = await axios.get(`${API_URL}/getPrice?itemCode=${encodeURIComponent(itemCode)}`);
      if (!res.data || res.data === "") {
        Vue.set(ctx.state.price, itemCode, {})
      } else {
        Vue.set(ctx.state.price, itemCode, res.data);
      }
    },
    async getSmallImage(ctx, itemCode) {
      const res = await axios.get(`${API_URL}/getSmallImage?itemCode=${encodeURIComponent(itemCode)}`);
      Vue.set(ctx.state.smallImage, itemCode, res.data);
    },
    async getImages(ctx, product) {
      const images = [];
      //if (product.FeaturedImageBigGUID) {
      if (product.ImgGUID || product.FeaturedImageBigGUID) {
        //const { data } = await axios.get(`${API_URL}/getImage?guid=${product.FeaturedImageBigGUID}&name=${product.FeaturedImageBigFileName}`);
        const { data } = await axios.get(`${API_URL}/getImage?guid=${product.ImgGUID || product.FeaturedImageBigGUID}&name=${product.ImgNaam || product.FeaturedImageBigFileName}`);
        images.push(data);
      }
      for (let i = 1; i <= 6; i++) {
        const key = 'Image' + i;
        if (product[key + 'GUID']) {
          const { data } = await axios.get(`${API_URL}/getImage?guid=${product[key + 'GUID']}&name=${product[key + 'Filename']}`);
          images.push(data);
        }
      }
      Vue.set(ctx.state.images, product.ItemCode, images);
    },
    async createOrder(ctx, cart) {
      try {
        await axios.post(`${API_URL}/createOrder`, cart);
      } catch (e) {
        ctx.state.orderError = e.response?.data || 'Onbekende fout'
      }
    }
  },
  modules: {
  }
});
export default store;

Vue.use({
  install(Vue, options) {
    Vue.prototype.t = (text) => {
      return {
        dutch: {
          searchText: "Zoeken",
          recentlyPurchasedText: 'Recent gekocht',
          searchCategoryText: 'In categorieën',
          searchProductText: 'In artikelen',
          priceText: "Prijs",
          categoryText: "Categorie",
          quantityText: "Aantal",
          productText: "Artikel",
          cartTotalText: "Totaal",
          orderText: "BESTELLEN",
          orderSummaryText1: "Je staat op het punt om",
          orderSummaryText2: "artikel(en) te bestellen met een totale waarde van",
          orderNumberText: "Opdrachtnummer (optioneel)",
          commentText: "Opmerking (optioneel)",
          termsText: "Ik ga akkoord met de algemene voorwaarden",
          confirmText: "BEVESTIGEN",
          cancelText: "NOG NIET",
          specialOrderText1: "Je hebt een artikel of meerdere artikelen gekozen die speciaal voor jou besteld moeten worden. Je kunt deze herkennen aan dit symbool",
          specialOrderText2: "Hiervoor worden aanvullende kosten in rekening gebracht.",
          specialOrderWarningText: 'Wanneer de bestelling door de klant wordt geannuleerd, worden alle gemaakte kosten, inclusief speciaal bestelde artikelen, in rekening gebracht. ',
          emptyCartText: 'Je winkelwagentje is leeg...',
          submittingOrderText: 'Bestelling plaatsen...',
          submitSuccesfulText: 'Bestelling geplaatst',
          submitUnSuccesfulText: 'Er ging iets fout met het plaatsen van je bestelling',
          thankYouText: 'Bedankt voor je bestelling!',
          readErrorText: 'We hebben de items in je winkelmandje laten staan.',
          forbiddenText: 'Je account heeft geen actieve organisatie.' + '<br>' + 'Probeer het alsjeblieft opnieuw of neem contract op met je administrator.',
          unauthorizedText: 'Het lijkt erop dat de OutSiteShop niet juist is geïntegreerd met AFAS OutSite.' + '<br>' + 'Neem alsjeblieft contact op met je administrator.',
          toShopText: 'NAAR DE SHOP'
        },
        english: {
          searchText: "Search",
          recentlyPurchasedText: 'Recently purchased',
          searchCategoryText: 'In categories',
          searchProductText: 'In products',
          priceText: "Price",
          categoryText: "Category",
          quantityText: "Quantity",
          productText: "Product",
          cartTotalText: "Total",
          orderText: "ORDER NOW",
          orderSummaryText1: "You are about to order",
          orderSummaryText2: "item(s) with a total product value of",
          orderNumberText: "Order number (Optional)",
          commentText: "Comment (optional)",
          termsText: "I AGREE TO THE TERMS AND CONDITIONS",
          confirmText: "CONFIRM",
          cancelText: "NOT YET",
          specialOrderText1: "You ordered an item or multiple items that need to be specially ordered for you. You can recognize them by this symbol",
          specialOrderText2: "Additional costs will be added.",
          specialOrderWarningText: 'In case of cancellation of the order by the customer, all additional costs that are made by DVH, including the special ordered items, will be charged.',
          emptyCartText: 'Your cart is empty...',
          submittingOrderText: 'Submitting Order...',
          submitSuccesfulText: 'Order Submitted',
          submitUnSuccesfulText: 'Something went wrong with submitting your order',
          thankYouText: 'Thank you for your order!',
          readErrorText: 'We kept the items in your card.',
          forbiddenText: 'Your account does not have an active organization.' + '<br>' +  'Please try again, or contact your administrator.',
          unauthorizedText: 'It looks like OutSiteShop is not correctly integrated on your AFAS OutSite.' + '<br>' + 'Please contact your administrator.',
          toShopText: 'GO TO SHOP'
        },
      }[store.state.language][text];
    }
  }
});
